import React, { Component } from 'react'
import "./styles.css"

export default class ActiveButton extends Component {
  render() {
    return (
      <div id="demo-container">
        <h1>The title of this article</h1>
        <h5>Table of contents</h5>
        <h6>Content item</h6>
        <p>This text will be styled differently</p>
        <label>
            Color name
            <input type="text" />
        </label>
        <input type="color" defaultValue="#D90000" />
        <button>Name Color!</button>
      </div>
    );
  }
}
