import React, { Component } from 'react'
import styled from 'styled-components'

const ParentContainer = styled.div`
  width: 100%;
  height: 5em;
  border: 1px solid black;
  padding: 0.5em 0.5em 0em 0.5em;
`
const ChildContainer = styled.div`
  border: 1px solid blue;
  width: 30%;
  height: 30%;
  margin: 0 auto;
`

export default class PaddingDemo extends Component {
  render() {
    return (
      <ParentContainer>
        <ChildContainer />
        <ChildContainer />
        <ChildContainer />
      </ParentContainer>
    );
  }
}