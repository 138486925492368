import React, { Component } from 'react'
import styled from 'styled-components'

const ParentContainer = styled.div`
  width: 100%;
  height: 5em;
  border: 1px solid black;
`
const ChildContainer = styled.div`
  border: 1px solid blue;
  width: 30%;
  height: 30%;
`

export default class PaddingDemo extends Component {
  render() {
    return (
      <ParentContainer>
        <ChildContainer />
        <ChildContainer />
        <ChildContainer />
      </ParentContainer>
    );
  }
}