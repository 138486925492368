import React, { PureComponent } from 'react'
import "./styles.css"

export default class AlternatingRow extends PureComponent {
  render() {
    return (
      <div id="row-container">
        <div className="container">
          <div className="child"></div>
          <div className="child"></div>
          <div className="child"></div>
          <div className="child"></div>
          <div className="child"></div>
          <div className="child"></div>
          <div className="child"></div>
          <div className="child"></div>
        </div>
      </div>
    );
  }
}
